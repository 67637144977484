import { Controller } from '@hotwired/stimulus'
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  static targets = ['tooltip']

  connect() {
    if (!this.hasTooltipTarget) { return }

    const tooltipElement = this.tooltipTarget

    if (tooltipElement.getAttribute('data-bs-title')) {
      const allowHtml = tooltipElement.dataset.html === 'true'
      const tooltip = new Tooltip(tooltipElement, { html: allowHtml })
    }
    this.tooltipTarget.addEventListener('shown.bs.tooltip', this.handleKeyBoardInteraction.bind(this))
  }

  handleKeyBoardInteraction() {
    const tooltip = Tooltip.getInstance(this.tooltipTarget)

    this.tooltipTarget.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        tooltip.hide()
      }
    })
  }

  disconnect() {
    if (!this.hasTooltipTarget) { return }
    // get rid of lingering tooltips when using turbo streams
    const tooltip = Tooltip.getInstance(this.tooltipTarget)
    if (tooltip !== null)
      tooltip.dispose()
    this.tooltipTarget.removeEventListener('keydown', this.handleKeyBoardInteraction.bind(this))
  }
}
