import { Controller } from '@hotwired/stimulus'
import snackbarCheckImg from 'images/admin/org/snackbar-check.svg'
import { Toast } from 'bootstrap'

export default class extends Controller {
  connect() {
    this.handleToastMessage = this.handleToastMessage.bind(this)
    this.liveRegion = document.getElementById('live-region-polite')
    document.addEventListener('toast', this.handleToastMessage)
  }

  disconnect() {
    document.removeEventListener('toast', this.handleToastMessage)
  }

  handleToastMessage(event) {
    let toastTemplate = `
    <div class="toast hide" data-delay="7000">
      <div class="d-flex">
        ${this.handleToastImg(event)}
        <div class="ms-2">
          ${event.detail.message}
        </div>
      </div>
    </div>
    `
    document.querySelectorAll('.toast').forEach(el => el.remove())
    if(this.liveRegion) {
      this.liveRegion.insertAdjacentHTML('beforeend', toastTemplate)
      this.checkLiveRegionVisibility()
    } else {
      document.body.insertAdjacentHTML('beforeend', toastTemplate)
    }
    const toastEl = document.querySelector('.toast')
    const toast = new Toast(toastEl)
    toast.show()
  }

  handleToastImg(event) {
    return event.detail.failure ? '' : `<img src="${snackbarCheckImg}" alt=""></img>`
  }

  checkLiveRegionVisibility() {
    if(this.liveRegion.classList.contains('visually-hidden')) {
      this.liveRegion.classList.toggle('visually-hidden')
      setTimeout(() => this.liveRegion.classList.toggle('visually-hidden'), 8000)
    }
  }

}
